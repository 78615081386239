<template>
  <content-section :spacing="false">
    <div class="content-spacing">
      <div class="grid grid-cols-1 mb-10 md:grid-cols-2 md:justify-between">
        <title-plus :title="pageTitle" :hide-plus="true" />

        <div class="md:flex md:justify-end">
          <DateRangePicker
            @apply-date="onApplyFilterDateRange"
            @cancel-date="onApplyFilterDateRange"
          />
        </div>
      </div>

      <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
        <SummaryCard
          :title="$t('components.riderManagement.summary.totalRiders')"
          :value="indexMetaData.summary.total"
          variant="gray"
        />

        <SummaryCard
          :title="$t('components.riderManagement.summary.activeRiders')"
          :value="indexMetaData.summary.active"
          variant="gray"
        />

        <SummaryCard
          :title="$t('components.riderManagement.summary.addedCard')"
          :value="indexMetaData.summary.added_card"
          variant="gray"
        />

        <SummaryCard
          :title="$t('components.riderManagement.summary.negativeBalance')"
          :value="indexMetaData.summary.negative_balance"
          variant="gray"
        />
      </div>
    </div>
    <div
      v-if="$acl.canNotView('riders')"
      class="py-5 font-bold text-center text-gray-600"
    >
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
    <template v-else>
      <FSTable
        :fst-id="fstId"
        :qso="qso"
        :endpoint="endpoint"
        :headers="getTableHeaders"
        :exportType="exportKey.RIDER"
        :exportFromURL="true"
        :isAISyncEnabled="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="getFilteredItems"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem>
                  <router-link
                    :to="{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.id },
                    }"
                    :class="`text-blue-600`"
                    target="_blank"
                  >
                    <div class="flex gap-1">
                      <span v-tooltip.bottom="item.last_ip_address">{{
                        item.full_name
                      }}</span>
                      <img
                        v-if="item.is_ip_blocked"
                        v-tooltip.bottom="`Blacklisted`"
                        class="w-4 h-4 "
                        src="@/assets/icon/blacklist_user.svg"
                      />
                    </div>
                  </router-link>
                </FSTableRowItem>

                <FSTableRowItem :text="item.phone_number" />
                <FSTableRowItem :text="item.last_otp_code" />

                <FSTableRowItem :text="item.joining_date" date />

                <FSTableRowItem>
                  <TimeAgo
                    :title="item.last_login"
                    :datetime="item.last_login"
                    :auto-update="60"
                  />
                  <!-- {{ item.last_login }} -->
                </FSTableRowItem>

                <FSTableRowItem>
                  <x-status
                    :text="getUserTripStatus(item.last_trip_status)"
                    :variant="getUserTripStatusBadge(item.last_trip_status)"
                    profile="trip"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <div v-if="item.default_card">
                    <i :class="getPaymentCardIconClass(item.default_card)"></i>
                    <span class="ml-1">**{{ item.default_card.number }}</span>
                  </div>
                  <div v-else>--</div>
                </FSTableRowItem>

                <FSTableRowItem :text="item.app_device_model" />

                <!-- <FSTableRowItem :text="item.app_version" /> -->
                <FSTableRowItem>
                  <VerifiedStatus :status="item.is_doc_verified" />
                  <!-- <template v-if="item.is_document_uploaded">
                  <VerifiedStatus :status="item.is_doc_verified" />
                </template>
                <template v-else>
                  <span>--</span>
                </template> -->
                </FSTableRowItem>

                <FSTableRowItem>
                  {{ symbol }}
                  {{ parseFloat(item.balance).toFixed(2) }}
                </FSTableRowItem>

                <FSTableRowItem>
                  <MoreActionsDropdown
                    :primary-key="itemIndex"
                    :currentVal="item.user_status"
                    :onOffEndpoint="getActionsEndpoint(item.id)"
                    :onOffEnabled="true"
                    :actionOnText="
                      $t('components.riderManagement.action.activate')
                    "
                    :actionOffText="
                      $t('components.riderManagement.action.deactivate')
                    "
                    actionOnKey="user_status"
                    actionOffKey="user_status"
                    actionOnVal="A"
                    actionOffVal="D"
                    @actionOn="item.user_status = 'A'"
                    @actionOff="item.user_status = 'I'"
                    :actionHoldEnabled="true"
                    :actionHoldText="
                      $t('components.riderManagement.action.hold')
                    "
                    :actionHoldEndpoint="getActionsEndpoint(item.id)"
                    actionHoldKey="user_status"
                    actionHoldVal="H"
                    @actionHold="item.user_status = 'H'"
                    :actionForceSyncEnabled="true"
                    :actionForceSyncEndpoint="getForceSyncEndpoint(item.id)"
                    :riderPhoneNumber="item.phone_number"
                    actionMakeWhiteListText="Make Whitelist"
                    :actionMakeWhiteListEnabled="item.is_ip_blocked"
                    :actionNotifyRiderEnabled="true"
                    :rawData="item"
                    @refresh="refreshPage"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>

                <FSTableRowItem>
                  <router-link
                    :to="{
                      name: 'ViewRiderUserProfile',
                      params: { id: item.id },
                    }"
                    :class="`text-blue-600`"
                    target="_blank"
                  >
                    <div class="flex gap-1">
                      <span v-tooltip.bottom="item.last_ip_address">{{
                        item.full_name
                      }}</span>
                      <img
                        v-if="item.is_ip_blocked"
                        v-tooltip.bottom="`Blacklisted`"
                        class="w-4 h-4 "
                        src="@/assets/icon/blacklist_user.svg"
                      />
                    </div>
                  </router-link>
                </FSTableRowItem>

                <FSTableRowItem :text="item.phone_number" />

                <FSTableRowItem>
                  <x-status
                    :text="getUserTripStatus(item.last_trip_status)"
                    :variant="getUserTripStatusBadge(item.last_trip_status)"
                    profile="trip"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{ $t('components.riderManagement.table.columns.otp') }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.last_otp_code || `--` }}
                    </div>
                  </div>

                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderManagement.table.columns.joiningDate'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div class="flex items-center">
                        {{ item.joining_date | friendlyDateTime }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.riderManagement.table.columns.lastLogin')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.last_login | friendlyDateTime }}
                      <p>
                        (<TimeAgo
                          :title="item.last_login"
                          :datetime="item.last_login"
                          :auto-update="60"
                        />)
                      </p>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.riderManagement.table.columns.defaultCard'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <div v-if="item.default_card">
                        <i
                          :class="getPaymentCardIconClass(item.default_card)"
                        ></i>
                        <span class="ml-1"
                          >**{{ item.default_card.number }}</span
                        >
                      </div>
                      <div v-else>--</div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.riderManagement.table.columns.device')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.app_device_model || `--` }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{ $t('components.riderManagement.table.columns.doc') }}
                    </div>
                    <div class="col-span-5 right-text">
                      <VerifiedStatus :status="item.is_doc_verified" />
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.riderManagement.table.columns.balance')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ symbol }}
                      {{ parseFloat(item.balance).toFixed(2) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t('components.riderManagement.table.columns.actions')
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <MoreActionsDropdown
                        :primary-key="itemIndex"
                        :currentVal="item.user_status"
                        :onOffEndpoint="getActionsEndpoint(item.id)"
                        :onOffEnabled="true"
                        :actionOnText="
                          $t('components.riderManagement.action.activate')
                        "
                        :actionOffText="
                          $t('components.riderManagement.action.deactivate')
                        "
                        actionOnKey="user_status"
                        actionOffKey="user_status"
                        actionOnVal="A"
                        actionOffVal="D"
                        @actionOn="item.user_status = 'A'"
                        @actionOff="item.user_status = 'I'"
                        :actionHoldEnabled="true"
                        :actionHoldText="
                          $t('components.riderManagement.action.hold')
                        "
                        :actionHoldEndpoint="getActionsEndpoint(item.id)"
                        actionHoldKey="user_status"
                        actionHoldVal="H"
                        @actionHold="item.user_status = 'H'"
                        :actionForceSyncEnabled="true"
                        :actionForceSyncEndpoint="getForceSyncEndpoint(item.id)"
                        :riderPhoneNumber="item.phone_number"
                        actionMakeWhiteListText="Make Whitelist"
                        :actionMakeWhiteListEnabled="item.is_ip_blocked"
                        :actionNotifyRiderEnabled="true"
                        :rawData="item"
                        @refresh="refreshPage"
                      />
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </template>
  </content-section>
</template>

<script>
import { RiderUserConfig } from '@/config/RiderUserConfig'
import { StatusConfig } from '@/config/StatusConfig'
import { exportKey } from '@/utils/export-data/helper.js'

import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import MoreActionsDropdown from '@/components/dropdown/MoreActionsDropdown'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import XStatus from '@/components/badge/XStatus'
import VerifiedStatus from '@/views/user/rider/VerifiedStatus.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'

export default {
  name: 'RiderIndex',
  props: {
    pageTitle: {
      type: String,
      default: 'Rider Management',
      required: false,
    },
    fstId: {
      type: String,
      default: 'riders-index',
      required: false,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    endpoint: {
      type: String,
      default: RiderUserConfig.api.index,
      required: false,
    },

    filterItems: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  components: {
    ContentSection,
    TitlePlus,

    SummaryCard,
    DateRangePicker,
    XStatus,
    VerifiedStatus,
    MoreActionsDropdown,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
  },
  data() {
    return {
      exportKey,
      windowWidth: window.innerWidth,
      indexMetaData: {
        summary: {
          total: 0,
          active: 0,
        },
      },
      tableHeaders: [
        // { text: 'ID', width: '10%', sort: 'id' },
        {
          text: this.$t('components.riderManagement.table.columns.name'),
          width: '10%',
          sort: 'full_name',
        },
        {
          text: this.$t('components.riderManagement.table.columns.phoneNumber'),
          width: '10%',
          sort: 'phone_number',
        },
        {
          text: this.$t('components.riderManagement.table.columns.otp'),
          width: '5%',
          sort: 'last_otp_code',
        },
        {
          text: this.$t('components.riderManagement.table.columns.joiningDate'),
          width: '15%',
          sort: 'joining_date',
        },
        {
          text: this.$t('components.riderManagement.table.columns.lastLogin'),
          width: '10%',
          sort: 'last_login',
        },
        {
          text: this.$t(
            'components.riderManagement.table.columns.lastTripStatus'
          ),
          width: '10%',
          sort: 'last_trip_status',
        },
        {
          text: this.$t('components.riderManagement.table.columns.defaultCard'),
          width: '10%',
          sort: 'default_card',
        },
        {
          text: this.$t('components.riderManagement.table.columns.device'),
          width: '10%',
          sort: 'app_device_model',
        },
        // { text: 'Version', width: '10%', sort: 'app_version' },
        {
          text: this.$t('components.riderManagement.table.columns.doc'),
          width: '5%',
          sort: 'is_doc_verified',
        },
        {
          text: this.$t('components.riderManagement.table.columns.balance'),
          width: '10%',
          sort: 'profile__balance',
        },
        {
          text: this.$t('components.riderManagement.table.columns.actions'),
          width: '5%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.riderManagement.table.columns.name'),
          width: '35%',
          sort: 'full_name',
        },
        {
          text: this.$t('components.riderManagement.table.columns.phoneNumber'),
          width: '35%',
          sort: 'phone_number',
        },
        {
          text: this.$t(
            'components.riderManagement.table.columns.lastTripStatus'
          ),
          width: '20%',
          sort: 'last_trip_status',
        },
      ],
      opened: [],

      // generated by generateFilterOptions()
      filterOptions: [],
    }
  },
  async created() {
    this.symbol = this?.$org?.default_currency?.symbol || '$'
    await this.generateFilterOptions()
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    refreshPage() {
      this.$store.dispatch('fsTable/fetchData')
    },
    getPaymentCardIconClass(card) {
      console.log('card-details', card)
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
    onApplyFilterDateRange,
    getUserTripStatus(status) {
      const statuses = {
        N: 'Completed',
        R: 'On Reserved',
        T: 'On Rent',
        P: 'On Pay',
        F: 'On Feedback',
      }
      return status in statuses ? statuses[status] : '--'
    },
    getUserTripStatusBadge(status) {
      const badges = {
        N: 'green',
        R: 'orange',
        T: 'purple',
        P: 'yellow',
        F: 'blue',
      }
      return status in badges ? badges[status] : 'gray'
    },
    getActionsEndpoint(id) {
      return StatusConfig.api.rider(id)
    },
    getForceSyncEndpoint(id) {
      return useEndpoints.rider.status.forceSync(id)
    },
    async generateFilterOptions() {
      let filters = [
        {
          type: 'checkbox',
          key: 'fraud_status',
          input: [
            { text: 'Normal', value: 'N' },
            { text: 'Suspicious', value: 'S' },
            { text: 'Block Listed', value: 'B' },
          ],
          title: 'Fraud Status',
        },
        {
          type: 'checkbox',
          key: 'trip_status',
          input: [
            { text: 'Completed', value: 'N' },
            { text: 'Reservation', value: 'R' },
            { text: 'On Rent', value: 'T' },
            { text: 'On Pay', value: 'P' },
            { text: 'On Feedback', value: 'F' },
          ],
          title: 'Trip Status',
        },
        {
          type: 'radio',
          key: 'is_doc_verified',
          input: [
            { text: 'Verified DOC', value: 'true' },
            { text: 'Unverified DOC', value: 'false' },
          ],
          title: 'Document Status',
        },
        {
          type: 'radio',
          key: 'user_status',
          input: [
            { text: 'On Hold Acc', value: 'H' },
            { text: 'Verified Acc', value: 'A' },
            { text: 'Unverified Acc', value: 'D' },
          ],
          title: 'Account Status',
        },
        {
          type: 'radio',
          key: 'document_uploaded',
          input: [
            { text: 'Yes', value: 'true' },
            { text: 'No', value: 'false' },
          ],
          title: 'Document Uploaded',
        },
        {
          type: 'radio',
          key: 'card_added',
          input: [
            { text: 'Yes', value: 'true' },
            { text: 'No', value: 'false' },
          ],
          title: 'Card Added',
        },
        {
          key: 'balance',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            min: -9999,
            max: 9999,
            minQs: 'min_balance', // query string for min value
            maxQs: 'max_balance', // query string for max value
          },
          title: 'Balance',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
</style>
